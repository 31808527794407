exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("./../../../node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-candidate-jsx": () => import("./../../../src/pages/404-candidate.jsx" /* webpackChunkName: "component---src-pages-404-candidate-jsx" */),
  "component---src-pages-404-employer-jsx": () => import("./../../../src/pages/404-employer.jsx" /* webpackChunkName: "component---src-pages-404-employer-jsx" */),
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-about-us-jsx": () => import("./../../../src/pages/about-us.jsx" /* webpackChunkName: "component---src-pages-about-us-jsx" */),
  "component---src-pages-ai-ready-data-checklist-jsx": () => import("./../../../src/pages/ai-ready-data-checklist.jsx" /* webpackChunkName: "component---src-pages-ai-ready-data-checklist-jsx" */),
  "component---src-pages-allen-recruitment-case-study-jsx": () => import("./../../../src/pages/allen-recruitment-case-study.jsx" /* webpackChunkName: "component---src-pages-allen-recruitment-case-study-jsx" */),
  "component---src-pages-bountique-case-study-jsx": () => import("./../../../src/pages/bountique-case-study.jsx" /* webpackChunkName: "component---src-pages-bountique-case-study-jsx" */),
  "component---src-pages-bridgeview-case-study-jsx": () => import("./../../../src/pages/bridgeview-case-study.jsx" /* webpackChunkName: "component---src-pages-bridgeview-case-study-jsx" */),
  "component---src-pages-construction-industry-jsx": () => import("./../../../src/pages/construction-industry.jsx" /* webpackChunkName: "component---src-pages-construction-industry-jsx" */),
  "component---src-pages-contact-us-jsx": () => import("./../../../src/pages/contact-us.jsx" /* webpackChunkName: "component---src-pages-contact-us-jsx" */),
  "component---src-pages-database-enrichment-jsx": () => import("./../../../src/pages/database-enrichment.jsx" /* webpackChunkName: "component---src-pages-database-enrichment-jsx" */),
  "component---src-pages-database-enrichment-promo-credits-jsx": () => import("./../../../src/pages/database-enrichment-promo-credits.jsx" /* webpackChunkName: "component---src-pages-database-enrichment-promo-credits-jsx" */),
  "component---src-pages-enrich-your-bullhorn-database-jsx": () => import("./../../../src/pages/enrich-your-bullhorn-database.jsx" /* webpackChunkName: "component---src-pages-enrich-your-bullhorn-database-jsx" */),
  "component---src-pages-faq-jsx": () => import("./../../../src/pages/faq.jsx" /* webpackChunkName: "component---src-pages-faq-jsx" */),
  "component---src-pages-faq-onboarding-jsx": () => import("./../../../src/pages/faq-onboarding.jsx" /* webpackChunkName: "component---src-pages-faq-onboarding-jsx" */),
  "component---src-pages-faq-technical-jsx": () => import("./../../../src/pages/faq-technical.jsx" /* webpackChunkName: "component---src-pages-faq-technical-jsx" */),
  "component---src-pages-healthcare-industry-jsx": () => import("./../../../src/pages/healthcare-industry.jsx" /* webpackChunkName: "component---src-pages-healthcare-industry-jsx" */),
  "component---src-pages-healthcare-staffing-software-jsx": () => import("./../../../src/pages/healthcare-staffing-software.jsx" /* webpackChunkName: "component---src-pages-healthcare-staffing-software-jsx" */),
  "component---src-pages-hiring-solution-jsx": () => import("./../../../src/pages/hiring-solution.jsx" /* webpackChunkName: "component---src-pages-hiring-solution-jsx" */),
  "component---src-pages-in-house-recruitment-solutions-jsx": () => import("./../../../src/pages/in-house-recruitment-solutions.jsx" /* webpackChunkName: "component---src-pages-in-house-recruitment-solutions-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-job-description-generator-jsx": () => import("./../../../src/pages/job-description-generator.jsx" /* webpackChunkName: "component---src-pages-job-description-generator-jsx" */),
  "component---src-pages-match-top-talent-with-ai-jsx": () => import("./../../../src/pages/match-top-talent-with-ai.jsx" /* webpackChunkName: "component---src-pages-match-top-talent-with-ai-jsx" */),
  "component---src-pages-miro-case-study-jsx": () => import("./../../../src/pages/miro-case-study.jsx" /* webpackChunkName: "component---src-pages-miro-case-study-jsx" */),
  "component---src-pages-pitchme-vs-alternatives-jsx": () => import("./../../../src/pages/pitchme-vs-alternatives.jsx" /* webpackChunkName: "component---src-pages-pitchme-vs-alternatives-jsx" */),
  "component---src-pages-press-about-us-jsx": () => import("./../../../src/pages/press-about-us.jsx" /* webpackChunkName: "component---src-pages-press-about-us-jsx" */),
  "component---src-pages-pricing-jsx": () => import("./../../../src/pages/pricing.jsx" /* webpackChunkName: "component---src-pages-pricing-jsx" */),
  "component---src-pages-professional-sector-industry-jsx": () => import("./../../../src/pages/professional-sector-industry.jsx" /* webpackChunkName: "component---src-pages-professional-sector-industry-jsx" */),
  "component---src-pages-propeller-recruitment-case-study-jsx": () => import("./../../../src/pages/propeller-recruitment-case-study.jsx" /* webpackChunkName: "component---src-pages-propeller-recruitment-case-study-jsx" */),
  "component---src-pages-provalidus-case-study-jsx": () => import("./../../../src/pages/provalidus-case-study.jsx" /* webpackChunkName: "component---src-pages-provalidus-case-study-jsx" */),
  "component---src-pages-solutions-for-recruitment-agencies-jsx": () => import("./../../../src/pages/solutions-for-recruitment-agencies.jsx" /* webpackChunkName: "component---src-pages-solutions-for-recruitment-agencies-jsx" */),
  "component---src-pages-spinks-case-study-jsx": () => import("./../../../src/pages/spinks-case-study.jsx" /* webpackChunkName: "component---src-pages-spinks-case-study-jsx" */),
  "component---src-pages-success-stories-jsx": () => import("./../../../src/pages/success-stories.jsx" /* webpackChunkName: "component---src-pages-success-stories-jsx" */),
  "component---src-pages-tech-and-it-industry-jsx": () => import("./../../../src/pages/tech-and-it-industry.jsx" /* webpackChunkName: "component---src-pages-tech-and-it-industry-jsx" */)
}

